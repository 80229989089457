<script setup lang="ts">
const { t } = useT();
const localPath = useLocalePath();

const { durationLeftFinished } = usePrizeDrop({ isOptimizedTimer: false });
</script>

<template>
	<NuxtLink :to="localPath('/prize-drops/')" class="stars-sidebar">
		<div class="content">
			<AText class="text-cannes" :modifiers="['bold']">
				{{ t("Shooting Stars") }}
			</AText>
			<AText variant="topeka" class="text-cannes">
				<i18n-t keypath="Ends in {key}">
					<template #key>
						<b>{{ durationLeftFinished }}</b>
					</template>
				</i18n-t>
			</AText>
		</div>
		<NuxtImg
			src="/nuxt-img/prize-drops/stars/sidebar.png"
			class="stars-img"
			width="112"
			height="112"
			format="webp"
			loading="lazy"
			alt="prize-drops-sidebar"
		/>
	</NuxtLink>
</template>

<style lang="scss" scoped>
.stars-sidebar {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	height: 56px;
	background: var(--gandia);
	border-radius: 8px;
	position: relative;
	cursor: pointer;
	padding: 12px 8px;

	.content {
		display: flex;
		flex-direction: column;
		gap: 3px;
	}

	.stars-img {
		position: absolute;
		right: 0;
		top: 0px;
		width: 56px;
		height: 56px;
	}
}
</style>
